<template>
  <section class="box-height" style="margin-bottom: 70px">
    <div class="box-title" style="border: 1px #e5e5e5 solid">
      <el-row :gutter="24">
        <el-col>
          <div style="float: left">
            <el-button
              type="default"
              plain
              size="mini"
              class="mgl10"
              @click="addNew"
            >
              <i class="el-icon-plus"></i> 添加角色
            </el-button>
            <el-button
              type="default"
              :disabled="isDisableChioce"
              plain
              size="mini"
              class="mgl10"
              @click="addNew('bulk')"
            >
              批量添加菜单权限
            </el-button>
          </div>

          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pagination.pageSize"
            layout="total,  prev, pager, next, sizes"
            :total="pagination.total"
          ></el-pagination>
        </el-col>
        <el-col :span="24">
          <el-table
            class="tabBorder custor"
            :header-row-style="{ height: '36px' }"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            :data="ListData"
            v-loading="listLoading"
            ref="handSelectTest_multipleTable"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="40"
              align="center"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="roleName"
              label="角色名称"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="creatorName"
              label="创建者"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="createdTime"
              label="创建时间"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop
              label="操作"
              align="left"
              width="150"
            >
              <template slot-scope="scope">
                <span
                  @click="handleEdit(scope.row.id, scope.row)"
                  class="tabHref mgr10"
                  >修改</span
                >
                <span
                  @click="handleDel(scope.row.id)"
                  class="tabHref"
                  v-if="!scope.row.isSysRole"
                  v-has="'setting:holiday:del'"
                  >删除</span
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pagination.pageSize"
            layout="total,  prev, pager, next, sizes"
            :total="pagination.total"
          ></el-pagination>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      width="720px"
      custom-class="settingDialog"
      :title="titName"
      :before-close="handleClose"
    >
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="mini"
          :loading="saveloading"
          @click="save('createDate')"
          >保存</el-button
        >
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="角色属性" name="first" v-if="isBulk">
          <el-form
            @submit.native.prevent
            :model="createDate"
            ref="createDate"
            :rules="rules"
            label-width="120px"
          >
            <el-row :gutter="24">
              <el-col :span="24">
                <el-form-item label="角色名称" prop="name">
                  <el-input
                    v-model.trim="createDate.name"
                    :readonly="readonly"
                    maxlength="20"
                    placeholder="请输入不要超过20个字"
                    style="width: 200px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="权限" name="second">
          <el-tree
            class="treeClass"
            :data="menuList"
            :props="defaultProps"
            :default-checked-keys="defaultcheckedMenu"
            node-key="id"
            ref="SlotMenuList"
            show-checkbox
            check-strictly
            @check="clickDeal"
          >
            <span class="slot-t-node" slot-scope="{ node, data }">
              <span v-show="data.children && data.children.length >= 1">
                <span>
                  <i
                    :class="
                      data.type == 1 ? 'fa fa-dot-circle-o' : 'fa fa-list'
                    "
                    style="margin-right: 5px"
                  ></i>
                  {{ node.label }}
                </span>
              </span>
              <span v-show="!data.children || data.children.length == 0">
                <i class style="margin-right: 10px"></i>
                <span>
                  <i
                    :class="
                      data.type == 1 ? 'fa fa-dot-circle-o' : 'fa fa-list'
                    "
                    style="margin-right: 5px"
                  ></i>
                  {{ node.label }}
                </span>
              </span>
            </span>
          </el-tree>
        </el-tab-pane>

        <el-tab-pane name="third" v-if="isBulk">
          <span slot="label">
            数据权限
            <el-tooltip placement="right-start" popper-class="tooltipStyle">
              <div slot="content">
                <div class="tipTitle">数据权限涉及范围</div>
                <div class="tipContent">
                  1.工作台-业绩目标-切换视角
                  <br />2.工作台-切换视角-管理视角
                  <br />3.工作台-管理考核-设置考核-配置考核范围
                  <br />4.数据看板-档案室 <br />5.数据看板-数据支持
                  <br />6.外呼系统 <br />7.数据看板-实时监控
                  <br />8.数据看板-业绩分析
                </div>
              </div>
              <i
                class="iconfont iconwenhao-xiangsu"
                style="font-size: 14px; margin-left: 4px"
              ></i>
            </el-tooltip>
          </span>
          <el-checkbox
            v-model="createDate.selfreadonly"
            @change="changeSelfreadonly"
            style="padding-left: 5px"
            >仅查看自己（勾选后无法选择其他层级）</el-checkbox
          >
          <el-tree
            class="treeClass"
            :data="OrganizinList"
            :props="defaultProps"
            :default-checked-keys="defaultchecked"
            node-key="id"
            ref="SlotOrgList"
            show-checkbox
            v-show="isSlotShow"
            @check="clickDep"
            check-strictly
          >
            <span class="slot-t-node" slot-scope="{ node, data }">
              {{ node.label }}
              <a v-if="data.type == '集团'">(集团)</a>
              <a v-else-if="data.type == '地区'">(地区)</a>
              <a v-else-if="data.type == '团队'">(团队)</a>
              <a v-else-if="data.type == '子团队'">(子团队)</a>
            </span>
          </el-tree>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </section>
</template>
<script>
import {
  addRoles,
  RolesList,
  getRoles,
  deleteRoles,
  MenuPermissionList,
  getDepartmentList,
  batchAddPermission, //批量添加菜单权限
} from "../../api/api";
export default {
  data() {
    return {
      ListData: [],
      titName: "",
      menuList: [], //权限数据
      defaultProps: {
        children: "children",
        label: "name",
      },
      OrganizinList: [], //组织架构
      defaultchecked: [],
      defaultcheckedMenu: [],
      saveloading: false,
      isSlotShow: true,
      listLoading: false,
      dialogVisible: false,
      activeName: "first",
      readonly: false, //是否只读
      createDate: {
        id: "",
        name: "",
        selfreadonly: false,
        departmentIds: [],
        permissionIds: [],
        version: 0,
      },
      rules: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请填写名称",
          },
        ],
      },
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 50,
      },
      multipleSelection: [],
      isDisableChioce: true,
      isBulk: true,
      itemIds: [],
    };
  },
  methods: {
    addNew(val) {
      if (val == "bulk") {
        this.isBulk = false;
        this.activeName = "second";
        this.titName = "批量添加菜单权限";
      } else {
        this.isBulk = true;
        this.activeName = "first";
        this.titName = "新增角色";
      }

      this.dialogVisible = true;
      this.createDate.id = "";
      this.resetCreateOrEditData();
      this.loadMenuList();
      this.loadOrganizinList();
    },
    save(formName) {
      if (this.isBulk) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveloading = true;
            if (this.createDate.selfreadonly) {
              this.createDate.departmentIds = [];
              this.createDate.selfreadonly = true;
            } else {
              this.createDate.selfreadonly = false;
              this.createDate.departmentIds =
                this.$refs.SlotOrgList.getCheckedKeys();
            }
            this.createDate.permissionIds =
              this.$refs.SlotMenuList.getCheckedKeys();
            if (this.createDate.permissionIds.length == 0) {
              this.$message.error("请先选择权限");
              this.saveloading = false;
              return false;
            }
            addRoles(this.createDate).then((res) => {
              if (res.success) {
                this.$message.success("操作成功");
                this.loadList();
                this.dialogVisible = false;
                this.resetForm(formName);
                this.createDate.departmentIds = [];
                this.createDate.permissionIds = [];
                this.activeName = "first";
              }
              this.saveloading = false;
            });
          }
        });
      } else {
        batchAddPermission({
          permissionIds: this.$refs.SlotMenuList.getCheckedKeys(),
          roleIds: this.itemIds,
        }).then((res) => {
          if (res.success) {
            this.$message.success("操作成功");
            this.loadList();
            this.dialogVisible = false;
            this.activeName = "first";
            this.isBulk = true;
          }
          this.saveloading = false;
        });
      }
    },
    clickDep(currentObj, treeStatus) {
      // 用于：父子节点严格互不关联时，父节点勾选变化时通知子节点同步变化，实现单向关联。
      let selected = treeStatus.checkedKeys.indexOf(currentObj.id); // -1未选中
      // 选中
      if (selected !== -1) {
        // 统一处理子节点为相同的勾选状态
        this.uniteChildSame1(currentObj, true);
      } else {
        // 未选中 处理子节点全部未选中
        if (currentObj.children && currentObj.children.length !== 0) {
          this.uniteChildSame1(currentObj, false);
        }
      }
    },
    clickDeal(currentObj, treeStatus) {
      // 用于：父子节点严格互不关联时，父节点勾选变化时通知子节点同步变化，实现单向关联。
      let selected = treeStatus.checkedKeys.indexOf(currentObj.id); // -1未选中
      // 选中
      if (selected !== -1) {
        // 子节点只要被选中父节点就被选中
        this.selectedParent(currentObj);
        // 统一处理子节点为相同的勾选状态
        this.uniteChildSame(currentObj, true);
      } else {
        // 未选中 处理子节点全部未选中
        if (currentObj.children && currentObj.children.length !== 0) {
          this.uniteChildSame(currentObj, false);
        }
      }
    },
    // 统一处理子节点为相同的勾选状态
    uniteChildSame(treeList, isSelected) {
      this.$refs.SlotMenuList.setChecked(treeList.id, isSelected);
      for (let i = 0; i < treeList.children.length; i++) {
        this.uniteChildSame(treeList.children[i], isSelected);
      }
    },
    // 统一处理父节点为选中
    selectedParent(currentObj) {
      let currentNode = this.$refs.SlotMenuList.getNode(currentObj);
      if (currentNode.parent.key !== undefined) {
        this.$refs.SlotMenuList.setChecked(currentNode.parent, true);
        this.selectedParent(currentNode.parent);
      }
    },
    // 统一处理子节点为相同的勾选状态
    uniteChildSame1(treeList, isSelected) {
      this.$refs.SlotOrgList.setChecked(treeList.id, isSelected);
      if (treeList.children) {
        for (let i = 0; i < treeList.children.length; i++) {
          this.uniteChildSame1(treeList.children[i], isSelected);
        }
      }
    },
    handleClick(tab, event) {
      if (tab.name == "third" && this.createDate.selfreadonly) {
        this.isSlotShow = false;
      } else {
        this.isSlotShow = true;
      }
    },
    handleEdit(rid, row) {
      // if (this.selfreadonly) {
      //   this.addDisabled(this.OrganizinList);
      // }
      this.isBulk = true;
      this.activeName = "first";
      this.titName = "修改角色";
      this.dialogVisible = true;
      this.createDate.id = rid;
      this.resetCreateOrEditData();
      this.loadMenuList();
      this.loadOrganizinList();
      getRoles({ id: rid }).then((res) => {
        if (res.success) {
          this.createDate.name = res.result.name;
          this.defaultchecked = res.result.departmentIds; //组织架构
          this.defaultcheckedMenu = res.result.permissionIds; //菜单
          this.createDate.version = res.result.version;
          this.readonly = res.result.isSysRole;
          this.createDate.selfreadonly = res.result.selfreadonly;
        }
      });
    },
    loadMenuList() {
      MenuPermissionList().then((res) => {
        if (res.success) {
          this.menuList = res.result;
        }
      });
    },
    loadOrganizinList() {
      getDepartmentList().then((res) => {
        if (res.success) {
          this.OrganizinList = res.result;
          if (this.OrganizinList) {
          }
        }
      });
    },
    handleClose(done) {
      done();
      if (this.isBulk) {
        this.resetForm("createDate");
      }
      this.activeName = "first";
      this.isBulk = true;
      this.dialogVisible = false;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    resetCreateOrEditData() {
      this.defaultchecked = [];
      this.readonly = false;
      this.defaultcheckedMenu = [];
    },
    handleDel(rId) {
      this.$confirm(
        '此操作将永久删除该角色, 是否继续?<br/><span style="color:red">（删除务必保证无用户勾选此角色）</span>',
        "提示",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteRoles({ id: rId }).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadList();
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除"
          // });
        });
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.pagination.pageNo = val;
      this.loadList();
    },
    loadList() {
      window.scrollTo(0, 0);
      this.listLoading = true;
      RolesList({
        current: this.pagination.pageNo,
        size: this.pagination.pageSize,
        searchCount: true,
      }).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.pagination.total = res.result.total;
        } else {
          this.listLoading = false;
        }
      });
    },
    changeSelfreadonly() {
      // 控制树形控件是否为禁用状态
      if (this.createDate.selfreadonly) {
        this.isSlotShow = false;
      } else {
        this.isSlotShow = true;
      }
      // this.addDisabled(this.OrganizinList);
    },
    // addDisabled(target) {
    //   let _this = this
    //   if(Array.isArray(target)) {
    //     target.forEach(i => {
    //       if($.isPlainObject(i)) {
    //         // i.disabled = _this.selfreadonly;
    //         _this.$set(i, 'disabled', _this.createDate.selfreadonly)
    //       }
    //       if(i.children && Array.isArray(i.children) && i.children.length > 0) {
    //         this.addDisabled(i.children);
    //       }
    //     })
    //   }
    // }
    handleSelectionChange(val) {
      this.multipleSelection = val;

      if (val.length) {
        this.isDisableChioce = false;
        // this.itemIds = val.map((i) => i.id);
      } else {
        this.isDisableChioce = true;
      }
      this.itemIds = val.map((i) => i.id);
    },
  },
  created() {
    this.loadList();
  },
};
</script>
<style lang="scss" scoped>
.box-title {
  padding: 15px 0;
}
.tipTitle {
  margin-bottom: 12px;
}
.tipContent {
  color: #cccccc;
}
</style>
<style>
.tooltipStyle .tipContent {
  color: #ccc;
}
.tooltipStyle.el-tooltip__popper {
  border: none;
}
</style>